import React, { useState, useEffect } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import qs from 'qs';
import Page from 'src/components/Page';
import Profile from './Profile';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = () => {
  const classes = useStyles();
  const [accessToken, setAccessToken] = useState('');
  const [user, setUser] = useState({
    picture: '',
    admin_of: [
      {
        space_subdomain: 'loading...',
        space_link: '',
        name: 'loading...',
        space_name: 'loading...'
      }
    ]
  });

  useEffect(() => {
    const { code } = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (code) {
      console.log(code);

      const clientId = '3376d63b9a07adadc5a1413f1d574130';
      const clientSecret =
        'a58119460d95810c9a12e4b346fa9175a2d679c16faab016ed5dc5a4ca3e4254';

      fetch('https://www.cobot.me/oauth/access_token', {
        body: new URLSearchParams(
          `client_id=${clientId}&client_secret=${clientSecret}&grant_type=authorization_code&code=${code}`
        ),
        method: 'post'
      })
        .then(response => response.json())
        .then(data => setAccessToken(data.access_token));
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetch('https://www.cobot.me/api/user', {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`
        })
      })
        .then(response => response.json())
        .then(data => setUser(data));

      /* fetch(
        `https://cors-anywhere.herokuapp.com/https://${user.admin_of[0].space_subdomain}.cobot.me/api/bookings?from=2020-10-01 12:00 +0000&amp;to=2020-10-30 18:00 +0000`,
        {
          method: 'get',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`
          })
        }
      )
        .then(response => response.json())
        .then(data => console.log(data)); */
    }
  }, [accessToken]);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xs={12}>
            <Profile user={user} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
